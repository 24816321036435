/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageChannels/pageEmail/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageChannels/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionLeft from "@components/sharedComponents/accordionLeft/accordionLeft"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageChannels/pageEmail/experience/experience"
import { TitleUnderlineLeftOne, TitleUnderlineLeftTwo } from "@components/pageChannels/pageEmail/titleUnderline/titleUnderlineAccordionLeft"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Conversions from "@components/pageChannels/pageEmail/conversions/conversions"
import NavbarVertical from "@components/sharedComponents/navbarVertical/navbarVertical"
import ImageVerticalOne from "@components/pageChannels/pageEmail/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalFour from "@components/pageChannels/pageEmail/images/imagesNavbarVertical/imageThree.webp"
import { TitleUnderlineNavbarVerticalOne, TitleUnderlineNavbarVerticalTwo } from "@components/pageChannels/pageEmail/titleUnderline/titleUnderlineNavbarVertical"

const TemplatePageEmail = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleAccordionLeft = <p>
    Te ofreceremos las herramientas necesarias para que tengas un mejor rendimiento en tus
    <TitleUnderlineLeftOne underline=" campañas de" /> <TitleUnderlineLeftTwo underline="marketing" />
  </p>
  const titleNavbarVertical = <p>
    Crea conexiones únicas con tus clientes en <TitleUnderlineNavbarVerticalOne underline="cada fase del" />  <TitleUnderlineNavbarVerticalTwo underline="embudo" />   de ventas
  </p>



  const objOne = {
    tab: "1",
    title: "Envío de ofertas y promociones",
    text: (
      <p>
        Tanto para fechas clave de descuentos o para nuevos lanzamientos de productos, fideliza a tus clientes por WhatsApp enviando mensajes únicos y personalizados de forma masiva. Todo esto en simples pasos, gracias a las integraciones nativas de nuestra solución.
      </p>
    ),
    image: ImageVerticalOne,
  }
  const objTwo = {
    tab: "1",
    title: "Envío de ofertas y promociones",
    text: (
      <p>
        Tanto para fechas clave de descuentos o para nuevos lanzamientos de productos, fideliza a tus clientes por WhatsApp enviando mensajes únicos y personalizados de forma masiva. Todo esto en simples pasos, gracias a las integraciones nativas de nuestra solución.
      </p>
    ),
    image: ImageVerticalFour,
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/productos/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/cMmmY0LElpo?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={true} background={"#FFF2D3"} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Ayudamos a empresas en todo el mundo a crear experiencias más personalizadas que generen conversión" />
          <AccordionLeft data={data.accordionLeft} title={titleAccordionLeft} description={""} />
          <BannerCTA
            title="Mejora la tasa de clics dentro de tus correos"
            text="Incrementa tu ticket de compra y mejora la retención de clientes con nosotros."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <NavbarVertical dataOne={data.navbarVerticalRight} dataTwo={data.navbarVerticalLeft}
            dataSilder={data.navbarVerticalSilder}
            objOne={objOne}
            objTwo={objTwo}
            title={titleNavbarVertical}
            description={""} />

          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="Perfecciona la tasa de entrega de tus campañas"
            text="Contact con un experto en mailing y consigue ayuda con tu caso de uso en específico."
            link="/hablemos/"
            textButton="Habla con un experto"
          />
          <Conversions data={data.conversions} location={location} />

          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageEmail
